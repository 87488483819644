import { createStore } from 'vuex'
import router from '@/router'
import Admin from "@/store/admin";
export default createStore({
  state() {
    return {
      routerIndex: 0,//代表当前使用的页面
      activePath: "/admin-index",
      routerMenus: [
        {
          name: "首页",
          path: "/admin-index",
          close: false
        }
      ],
      menuOnlyIcon: false
    }
  },
  getters: {
  },
  mutations: {
    toggleMenu(state) {
      state.menuOnlyIcon = !state.menuOnlyIcon
    },
    deleteRouterMenuItem(state, idx) {
      state.routerMenus.splice(idx, 1)
      if (state.routerIndex == idx) {
        state.routerIndex = 0 // 索引归0 代表显示首页
        router.push("/admin-index")
      } else if (idx < state.routerIndex) {
        // 处理删除其他元素索引变化导致的下次删除索引 无法对应自身问题
        // 当删除索引比当前索引小时 当前routerIndex当减少1
        state.routerIndex--
      }
    },
    addRouterMenuItem(state, item) {
      // 验证routerMenus里是否已经存在这个地址了
      // has默认false值代表含义是不存在这个地址
      let has = false;
      // 把数组循环一下 每个元素都做一下对比
      for (let i = 0; i < state.routerMenus.length; i++) {
        const elem = state.routerMenus[i];
        if (elem.path == item.path) {
          has = true
          break;//只要找到一个相同地址就可以结束循环了
        }
      }
      // 如果经过for循环以后 has是false 代表没有该地址 可以添加
      // 否者有这个地址 不添加
      if (!has) {
        state.routerIndex = state.routerMenus.length// 索引设置为当前添加的元素
        state.routerMenus.push({
          name: item.name,
          path: item.path,
          close: Boolean(item.path)
        })
      }
      // 修复了侧边栏点击不调转问题
      router.push(item.path)
    },
    routerMenuItemGo(state, idx) {
      state.routerIndex = idx
      // 顶部页面跳转没有记录缓存 需要记录一下
      state.activePath = state.routerMenus[idx].path;
      router.push(state.routerMenus[idx].path)
    },
    addActivePath(state, path) {
      localStorage.activePath = path
      state.activePath = path
    },
    initRouterMenus(state, routerMenus) {
      if (routerMenus.length > 1) {
        state.routerMenus = routerMenus
      }
    },
    initRouterIndex(state, index) {
      if (index > 0) {
        state.routerIndex = index
      }
    }
  },
  actions: {
  },
  modules: {
    Admin
  }
})
