const Admin = {
    state: () => ({
        adminInfo: {
            username: "",
            avatarUrl: "",
            roles: "",
            tel: ""
        }
    }),
    mutations: {
        setAdminInfo(state, admin = {
            username: "",
            avatarUrl: "",
            roles: "",
            tel: ""
        }) {
            state.adminInfo = admin
        }
    },
    actions: {},
    getters: {}
}

export default Admin