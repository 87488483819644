<template>
  <div class="house-add">
    <!-- 保存 -->
    <PageHeader class="tabs" :title="id == 0 ? '添加楼盘' : '编辑楼盘'" hidden-breadcrumb>
      <template #action>
        <Button type="primary" long @click="submitForm">保存</Button>
      </template>
    </PageHeader>

    <Tabs class="tabs" v-model="activeTab">
      <TabPane label="基础信息" name="0">
        <Form :model="editProject" :label-width="80">
          <FormItem label="楼盘名称:">
            <Input v-model="editProject.name" placeholder="请输入楼盘名称"></Input>
          </FormItem>
          <FormItem label="价格/m2:">
            <Input v-model="editProject.square_price" type="number" />
          </FormItem>
          <FormItem label="主力户型:">
            <Input v-model="editProject.intr" placeholder=""></Input>
          </FormItem>
          <!-- <FormItem label="平均价格:">
            <Input v-model="editProject.house_price" type="number" placeholder=""></Input>
          </FormItem> -->
          <FormItem label="排序:">
            <Input v-model="editProject.sort" type="number" placeholder=""></Input>
          </FormItem>
          <!-- 头像上传 -->
          <FormItem label="缩略图组:">
            <div style="display:flex;margin-bottom:10px;">
              <div style="position: relative;" v-for="(img, i) in editProject.image_list" :key="img.url">
                <Icon @click="delImg(i)" color="#ed4014" size="32"
                  style="position: absolute;right: -5px;top: -15px;cursor: pointer;" type="ios-close-circle-outline" />
                <img style="width:200px;margin-right:10px;" :src="img.url" />
              </div>
            </div>
            <UploadServer @getUrl="setImage"></UploadServer>
          </FormItem>

          <FormItem label="楼盘地址:">
            <Input v-model="editProject.address" placeholder="请输入楼盘地址"></Input>
          </FormItem>
          <FormItem label="楼盘标签:">
            <Tag v-for="(tag, i) in editProject.tag" :key="tag" closable @on-close="closeTag(i)">{{ tag }}</Tag>
            <Tag @click="tagModal = true">
              <Icon type="md-add" />
            </Tag>
          </FormItem>
          <FormItem label="开盘时间:">
            <!-- <Input  /> -->
            <DatePicker v-model="editProject.last_start" type="date" placeholder="请选择开盘日期" style="width: 200px" />
          </FormItem>
          <FormItem label="交房说明:">
            <Input v-model="editProject.delivery_time" placeholder="请输入交房说明"></Input>
          </FormItem>
          <FormItem label="联系方式:">
            <Input v-model="editProject.contact" placeholder="请输入联系方式"></Input>
          </FormItem>
          <FormItem label="楼盘详情:">
            <TEditor v-model="editProject.house_type"></TEditor>
          </FormItem>

          <FormItem label="户型图:">
            <TEditor v-model="editProject.around"></TEditor>
          </FormItem>
        </Form>
      </TabPane>
      <TabPane label="楼栋单元信息" name="1">
        <DescriptionList col="1" title="使用说明:" layout="vertical">
          <Description>
            楼号前的删除按钮会一次删除整个楼栋;添加楼栋时在当前页面下方输入楼栋名称,然后点击输入框后的添加按钮;<br>
            添加楼栋所属单元时点击该楼栋折叠面板,当展开楼栋信息时,内部有对应的输入框,输入完成以后点击输入框后的添加按钮<br>
            不需要的单元直接点击单元后对应的删除按钮;(最后点击右上角保存,房间信息返回楼盘列表点击查看房源修改)
          </Description>
        </DescriptionList>
        <Collapse accordion v-model="CollapseValue">
          <Panel :name="`build${i}`" v-for="(build, i) in editProject.building_list" :key="`build${i}`">
            <Button type="error" size="small" style="margin-right: 20px;" @click="removeBuild(i)">删除
              <Icon type="md-remove-circle" />
            </Button>
            {{ build.building }}
            <template #content>
              <List border>
                <ListItem v-for="(unit, j) in build.unit" :key="`unit${j}`">
                  <Input style="margin-right: 20px;" size="large" v-model="editProject.building_list[i].unit[j]" />
                  <Button type="error" @click="removeUnit(i, j)"> 删除
                    <Icon type="md-remove-circle" />
                  </Button>
                </ListItem>
                <ListItem>
                  <Input style="margin-right: 20px;" size="large" v-model="addUnitName" placeholder="请输入要添加的单元名" />
                  <Button type="primary" @click="addUnit(i)">添加
                    <Icon type="md-add" />
                  </Button>
                </ListItem>
              </List>
            </template>
          </Panel>
        </Collapse>
        <Row style="margin-top: 20px;align-items: center;">
          <Col span="20">
          <Input style="margin-right: 20px;" size="large" v-model="addBuildName" placeholder="请输入要添加的楼栋名" />
          </Col>
          <Col span="2" offset="1">
          <Button type="primary" @click="addBuild()">添加
            <Icon type="md-add" />
          </Button>
          </Col>

        </Row>
      </TabPane>
      <!-- <TabPane label="房源信息" name="2">
        <Row>
          <Col span="2" offset="22">
          <Button type="primary" @click="showAddHousePage">添加房源</Button>
          </Col>
        </Row>
        <Tabs value="cell0" @on-tab-remove="closeRemoveCell" type="card" style="margin-top: 10px;">
          <TabPane v-for="(cell, hc) in editProject.house_detail" :key="`cell${hc}`" :label="cell.building">
            <Tabs :value="`cell${hc}unit0`" @on-tab-remove="closeRemoveUnit" type="card">
              <TabPane v-for="(unit, ui) in cell.unit_list" :key="`cell${hc}-unit${ui}`" :label="unit.unit">
                <Tag size="large" v-for="(house, hi) in unit.house_list" :key="`cell${hc}-unit${ui}-house${hi}`" closable
                  @on-close="delHouse(hc, ui, hi)">
                  {{ house.house_no }} / {{ house.house_type }} / {{ house.area }}
                </Tag>
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </TabPane> -->
    </Tabs>


    <Modal v-model="tagModal" title="输入要添加的标签名" @on-ok="addTag">
      <p>
        <Input v-model="tagName" />
      </p>
    </Modal>
    <AddHouse v-if="showAddHouse" v-model="showAddHouse" :editData="editData" @add="(v) => data.push(v)"
      @update="(v) => data[uIndex] = v">
    </AddHouse>
  </div>
</template>

<script>
import { getone, update, add } from "@/api/house";
import AddHouse from "./AddHouse.vue";
export default {
  name: "BuildAdd",
  components: { AddHouse },
  data() {
    return {
      id: 0,
      model: true,
      tagModal: false,
      activeTab: "0",
      CollapseValue: "build0",
      addBuildName: "",
      addUnitName: "",
      showAddHouse: false,// 显示添加房源弹窗
      tagName: "",
      editProject: {
        name: "",
        square_price: 0,
        house: "",
        image_list: [],
        address: "",
        tag: [],
        last_start: "",
        house_type: "",
        around: "",
        house_detail: [],
        building_list: []
      }
    };
  },
  created() {
    let id = this.$route.query.id - 0
    let t = this.$route.query.type;
    if (id != 0) {
      this.id = id
      this.getDetail()
    }
    if (t == 'cell') {
      this.activeTab = '1'
    }
  },
  methods: {
    getDetail() {
      const msg = this.$Message.loading({
        content: '正在获取数据...',
        duration: 0
      });
      getone(this.id).then(res => {
        if (res.code === 0) {
          this.editProject = res.data
        }
      }).finally(() => {
        msg()
      })
    },
    setImage(v) {
      this.editProject.image_list.push(v);
    },
    delImg(i) {
      this.editProject.image_list.splice(i, 1);
    },
    removeBuild(i) {
      console.log(this.editProject.building_list[i]);
      this.editProject.building_list.splice(i, 1)
    },
    removeUnit(i, j) {
      this.editProject.building_list[i].unit.splice(j, 1)
    },
    addBuild() {
      console.log(this.addBuildName);
      if (this.addBuildName) {
        this.editProject.building_list.push({
          building: this.addBuildName,
          unit: []
        })
        this.addBuildName = ""
      } else {
        this.$Notice.error({
          title: "输入楼栋号不能为空"
        })
      }
    },
    addUnit(i) {
      if (this.addUnitName) {
        this.editProject.building_list[i].unit.push(this.addUnitName)
        this.addUnitName = ""
      } else {
        this.$Notice.error({
          title: "输入单元名不能为空"
        })
      }
    },
    submitForm() {
      let images_list = [];
      this.editProject.image_list.map(v => {
        images_list.push(v.file_id)
      })

      this.editProject.images_list = images_list;
      console.log(this.editProject.images_list);
      if (this.id === 0) {
        add(this.editProject).then(res => {
          if (res.code == 0) {
            this.$Notice.success({
              title: "添加成功", onClose: () => {
                this.$router.back()
              }
            })
          } else {
            this.$Notice.error({
              title: res.msg
            })
          }
        })
      } else {
        this.editProject.project_id = this.id
        update(this.editProject).then(res => {
          if (res.code == 0) {
            this.$Notice.success({
              title: "更新成功", onClose: () => {
                this.$router.back()
              }
            })
          } else {
            this.$Notice.error({
              title: res.msg
            })
          }
        })
      }

    },
    addTag() {
      if (this.tagName) {
        this.editProject.tag.push(this.tagName)
      }
    },
    delHouse(i, j, k) {
      console.log(i, j, k);
    },
    closeRemoveCell(n) {
      console.log(n);
    },
    closeRemoveUnit(n) {
      console.log(n);
    },
    showAddHousePage() {
      this.showAddHouse = true;
      this.editData = {
        project_id: this.id
      }
    },
    closeTag(i) {
      console.log(i);
      this.editProject.tag.splice(i, 1)
    }
  },
};
</script>

<style scoped>
.house-add {
  width: 95%;
  overflow: hidden;
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  margin-top: 50px;
}

.house-add .tabs {
  width: 97%;
}
</style>