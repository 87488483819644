<template>
  <div class="upload">
    <Image v-if="src" :src="src" height="200px" fit="fill" />
    <Upload :format="format" :max-size="4096" type="drag" :action="`${domain}/admin/file/upload`"
      name="image" :on-format-error="addError" :on-success="addFileSuccess" :headers="header">
      <div style="padding: 20px 0">
        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p>点击或者拖拽图片到这里上传</p>
      </div>
    </Upload>
  </div>
</template>

<script>
import config from "@/utils/config";
export default {
  name: "QiniuUp",
  computed:{
    domain(){
      return config.domain
    }
  },
  data() {
    return {
      format: ["jpg", "jpeg", "png", "webp"],
      header: {},
      src: "",
    };
  },
  props: {
    value: {
      default: "",
      type: String,
    },
    url: {
      default: "",
      type: String,
    },
  },
  created() {
    this.src = this.url;
    this.header.token = localStorage.token
  },
  methods: {
    addFileSuccess(request) {
      if (request.code != 0) {
        this.$Notice.error({ title: request.msg })
      } else {
        this.src = request.url;
        this.$emit("getUrl", { url: request.data.url, file_id: request.data.file_id });
      }

    },
    addError() {
      //request, file
      this.$Notice.warning({
        title: "格式错误",
        desc: `请上传${this.format.join("、")}格式文件`,
      });
    },
  },
};
</script>