<template>
  <Modal v-model="model" width="500" @on-ok="submitForm" @on-cancel="onCancle">
    <template #header>
      <p style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>用户信息编辑</span>
      </p>
    </template>
    <div style="text-align: center">
      <Form :model="editUser" :label-width="80">
        <FormItem label="姓名:">
          <Input v-model="editUser.user_name" placeholder="请输入用户姓名"></Input>
        </FormItem>

        <FormItem label="身份证:">
          <Input v-model="editUser.ID" placeholder="请输入用户身份证"></Input>
        </FormItem>

        <FormItem label="手机号:">
          <Input v-model="editUser.mobile" placeholder="请输入用户手机号"></Input>
        </FormItem>
      </Form>
    </div>
  </Modal>
</template>

<script>
import { update } from "@/api/user";


export default {
  name: "AdminEidt",
  data() {
    return {
      model: true,
      data: [],
      editUser: {
        user_id: "",
        ID: "", //管理员名称
        mobile: "",
        user_name: ""
      }
    };
  },
  props: {
    user: null,
  },
  created() {
    this.editUser = { user_id: this.user.user_id, ID: this.user.ID, mobile: this.user.mobile, user_name: this.user.user_name, };
  },
  methods: {
    submitForm() {
      update(this.editUser).then(res => {
        if (res.code == 0) {
          this.$Notice.success({ title: "更新成功" });
          this.$emit("closeEdit", this.editUser)

        } else {
          this.$Notice.error({ title: res.msg });
          this.$emit("closeEdit", null)
        }
        this.model = false;
      })
    },
    onCancle() {
      this.model = false;
      this.$emit("closeEdit")
    }
  },
};
</script>