/** 管理员API接口文件 */
import request from "./request";
const Req = new request()

export function list({ keyword, page, rows }) {
    return Req.get({
        url: '/user/list',
        data: {
            keyword, page, rows
        }
    })
}
export function del(user_id) {
    return Req.post({
        url: '/user/delete',
        data: {
            user_id
        }
    })
}
export function add({ ID, mobile, user_name }) {
    return Req.post({
        url: "/user/create",
        data: {
            ID, mobile, user_name
        }
    })
}

export function update({user_id, ID, mobile, user_name}) {
    return Req.post({
        url: "/user/update",
        data: {
            user_id, ID, mobile, user_name
        }
    })
}
