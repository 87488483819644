import request from "./request";
const Req = new request()
export function list({ name, page = 1, rows = 10 }) {
    return Req.get({
        url: '/project/list',
        data: {
            name,
            page,
            rows
        }
    })
}

export function getone(id) {
    return Req.get({
        url: `/project/detail?project_id=${id}`
    })
}

export function add({ name, sort, intr, contact, house_price = 0, square_price, house, image_list, images_list, address, tag, last_start, house_type, around, house_detail, building_list,delivery_time }) {
    return Req.post({
        url: "/project/create",
        data: {
            name, intr, sort, square_price, contact, house_price, house, image_list, images_list, address, tag, last_start, house_type, around, house_detail, building_list,delivery_time
        }
    })
}
export function update({ project_id, contact, house_price = 0, sort, name, square_price, house, images_list, address, tag, last_start, house_type, around, house_detail, intr, building_list,delivery_time }) {
    return Req.post({
        url: "/project/edit",
        data: {
            project_id, name, sort, contact, house_price, square_price, house, images_list, address, tag, last_start, house_type, around, house_detail, intr, building_list,delivery_time
        }
    })
}

export function add_unit_house({ project_id, building, unit, house_no, type, house_type, area }) {
    return Req.post({
        url: "/house/create",
        data: {
            project_id, building, unit, house_no, type, house_type, area
        }
    })
}

export function remove(project_id) {
    return Req.post({
        url: '/project/delete',
        data: {
            project_id
        }
    })
}