<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created(){
    this.$store.commit("addActivePath",localStorage.activePath )
  }
};
</script>

<style>
#app {

}
</style>
