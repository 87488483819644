<template>
  <Modal :value="value" width="500" @on-ok="submitForm" @on-cancel="onCancle">
    <template #header>
      <p style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>添加房源数据</span>
      </p>
    </template>

    <div style="text-align: center">
      <Form :model="data" :label-width="80">
        <FormItem label="楼号:">
          <Input v-model="data.building" placeholder="请输入楼号"></Input>
        </FormItem>
        <FormItem label="单元号:">
          <Input v-model="data.unit" placeholder="请输入单元号"></Input>
        </FormItem>
        <FormItem label="房间号:">
          <Input v-model="data.house_no" placeholder="请输入房间号"></Input>
        </FormItem>
        <FormItem label="类型:" style="text-align: left;">
          <RadioGroup v-model="data.type">
            <Radio label="0">商品房</Radio>
            <Radio label="1">公寓</Radio>
            <Radio label="2">商铺</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="户型:">
          <Input v-model="data.house_type" placeholder="请输入户型"></Input>
        </FormItem>
        <FormItem label="面积:">
          <Input v-model="data.area" type="number" placeholder="请输入户型面积"></Input>
        </FormItem>
      </Form>
    </div>
  </Modal>
</template>
<script>
import { add_unit_house } from "@/api/house";
export default {
  name: "EditNotice",
  data() {
    return {
      model: false,
    };
  },
  computed: {
    data() {
      let e = this.editData;
      return { ...e };
    },
  },
  props: {
    editData: {
      default: null,
      type: Object,
    },
    value: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onCancle() {
      this.$emit("input", false);
    },
    submitForm() {
      add_unit_house(this.data).then((res) => {
        if (res.code == 0) {
          this.$Notice.success({
            title: "添加房源成功"
          });
          this.$emit("add", this.data);
        }
        else {
          this.$Notice.error({
            title: "添加房源失败"
          });
        }
      });
    },
  }
};
</script>