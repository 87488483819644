<template>
    <div class="canvas-verify" :style="style">
        <Row style="height: 30px;line-height: 30px;padding-top: 5px;" class="ivu-pt-8 ivu-pb-8">
            <Col span="8" offset="2" class="ivu-text-left">安全验证</Col>
            <Col span="2" offset="12">
            <Icon type="md-close" @click="close" />
            </Col>
        </Row>
        <Row>
            <Col span="20" offset="2" class="ivu-text-left">
            <Title :level="4">拖动下方滑块完成验证</Title>
            </Col>
        </Row>
        <!-- 滑动图片版 -->
        <!-- <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight" @mousedown="onMouseDown"
            @mousemove="onMouseMove" @mouseup="onMouseUp"></canvas> -->

        <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
        <div style="position: relative;width: 300px;left: 10px;">
            <!-- <Progress style="width: 300px;" stroke-color="success" status="success" :percent="percent"
                :stroke-width="10" hide-info></Progress> -->
            <Slider class="verify-slider" v-model="sliderValue" :min="0" :max="100" show-tip="never"
                @on-input="sliderMove" @on-change="sliderUp"></Slider>
            <!-- <Button style="position: absolute;" :style="`left:${2.55 * percent}px;`" type="success"> | | |
            </Button> -->
        </div>
        <!-- <Row>
            <Col span="20" offset="2"  class="ivu-text-right">
            
                <Icon type="md-refresh" />
            </Col>
        </Row> -->
    </div>
</template>

<script>
import { duration } from 'moment';

export default {
    name: "Verify",
    props: {
        style: {
            default: "",
            type: String,
        },
    },
    data() {
        return {
            isDragging: false, // 是否正在拖动滑块
            thumbLeft: 0, // 滑块左边距
            canvasWidth: 300, // canvas宽度
            canvasHeight: 150, // canvas高度
            maxWidth: 250, // 滑块最大可移动距离
            dragStartX: 0, // 开始拖动时鼠标的x坐标
            dragOffsetX: 0, // 鼠标相对于滑块左边缘的偏移量
            canvasContext: null, // canvas context
            imagePath: '', // 背景图路径
            squreY: 50,
            squreX: 0,
            sliderValue: 0
        };
    },
    methods: {
        onMouseDown(e) {
            if (this.isDragging) {
                return;
            }
            const rect = this.$refs.canvas.getBoundingClientRect();
            this.isDragging = true;
            this.dragStartX = e.clientX - rect.left;
            this.dragOffsetX = this.dragStartX - this.thumbLeft;
        },
        onMouseMove(e) {
            if (this.isDragging) {
                const rect = this.$refs.canvas.getBoundingClientRect();
                const distance = e.clientX - rect.left - this.dragOffsetX;
                const thumbLeft = Math.min(Math.max(distance, 0), this.maxWidth);
                this.thumbLeft = thumbLeft;
                var percent = Math.ceil((thumbLeft * 100) / (this.squreX));
                if (percent >= 100) {
                    this.percent = 100
                } else {
                    this.percent = percent
                }
                this.draw();
            }
        },
        onMouseUp(e) {
            // console.log(e);
            if (this.isDragging) {
                this.isDragging = false;
                var result = this.squreX - this.thumbLeft < 20 ? true : false;
                var randomX = this.squreX
                var moveX = this.thumbLeft

                this.$emit("moveEnd", {
                    randomX: randomX,
                    moveX: moveX,
                    result: result
                });
                if (this.thumbLeft === this.maxWidth) {
                    this.$emit('success');
                } else {
                    // this.thumbLeft = 0;
                    this.draw();
                }
            }
        },
        draw() {
            // 绘制背景图
            const image = new Image();
            image.src = this.imagePath;
            image.onload = () => {
                if (!this.canvasContext) {
                    return
                }
                this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
                this.canvasContext.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight);
                // 清除指定区域为空白
                const imgData = this.canvasContext.getImageData(this.squreX, this.squreY, 50, 50);

                this.canvasContext.clearRect(this.squreX, this.squreY, 50, 50)

                // 绘制滑块
                this.canvasContext.fillStyle = '#f2f2f2';
                this.canvasContext.fillRect(this.thumbLeft - 1, 49, 52, 52);
                this.canvasContext.putImageData(imgData, this.thumbLeft, this.squreY);

                // 绘制滑块
                // this.canvasContext.fillStyle = '#ccc';
                // this.canvasContext.fillRect(this.thumbLeft, 50, 50, 50);
            };
        },
        sliderMove(e) {
            this.isDragging = true;
            this.thumbLeft = Math.ceil(((e - 0) / 100) * 250);
            this.draw();
        },
        sliderUp(e) {
            var result = this.squreX - this.thumbLeft < 20 ? true : false;
            if (!result) {
                this.$Message.error({
                    content: '验证错误,请重新验证',
                    duration: 1,
                });
                this.isDragging = false;
                this.thumbLeft = 0;
                this.sliderValue = 0;
                this.squreX = Math.floor(Math.random() * 100) + 100
                let num = Math.ceil(Math.random() * 4);
                this.imagePath = `./verify_imgs/${num}.jpg`;
                const image = new Image();
                image.src = this.imagePath;
                image.onload = () => {
                    this.draw();
                };
                return;
            }
            if (this.isDragging) {
                this.isDragging = false;

                var randomX = this.squreX
                var moveX = this.thumbLeft

                this.$emit("moveEnd", {
                    randomX: randomX,
                    moveX: moveX,
                    result: result
                });
                if (this.thumbLeft === this.maxWidth) {
                    this.$emit('success');
                } else {
                    // this.thumbLeft = 0;
                    this.draw();
                }
            }
        },
        close() {
            this.$emit("close", false);
        }
    },
    mounted() {
        this.squreX = Math.floor(Math.random() * 100) + 100
        // 获取canvas context
        this.canvasContext = this.$refs.canvas.getContext('2d');
        // 加载背景图
        // this.imagePath = 'https://picsum.photos/300/150/?random';
        let num = Math.ceil(Math.random() * 4);
        this.imagePath = `./verify_imgs/${num}.jpg`;

        const image = new Image();
        image.src = this.imagePath;
        image.onload = () => {
            this.draw();
        };
    },
    beforeUnmount() {
        this.canvasContext = null;
    },
};
</script>

<style scoped>
.canvas-verify {
    position: fixed;
    z-index: 998;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    width: 320px;
    height: 270px;
}

.verify-slider {
    width: 85%;
    position: absolute;
    top: -2px;
    z-index: 999;
    left: 5px;
    /* opacity: 0; */
}


::v-deep .ivu-slider-wrap {
    background-color: #ffff;
}

::v-deep .ivu-slider-button {
    width: 50px;
    height: 32px;
    border-radius: 4px;
    border: 0;
    position: relative;
    top: -10px;
    color: #fff;
    background-color: #19be6b;
    border-color: #19be6b;
}

::v-deep .ivu-slider-button::after {
    position: absolute;
    content: " | | |";
    left: 16px;
    top: 6px;
    font-weight: bold;
}

::v-deep .ivu-slider-button:hover {
    transform: scale(1.1);
}

::v-deep .ivu-slider-bar {
    background-color: #19be6b;
}
</style>