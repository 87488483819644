<template>
  <div class="list">
    <PageHeader title="用户列表" hidden-breadcrumb>
      <template #action>
        <Row>
          <Input v-model="keyword" placeholder="输入要查询的用户 姓名/身份证/手机号..." clearable style="width: 300px;" />
          <Button type="primary" @click="getList">
            <Icon type="md-search" size="24" color="rgb(92, 219, 211)" />
          </Button>
          <Button type="info" @click="exportModel" style="margin:0 10px;">下载用户Excel导入模板</Button>
          <Button type="primary" @click="showAddModel">添加</Button>
        </Row>
      </template>
    </PageHeader>
    <Table border ref="selection" :columns="columns" :data="users">
      <template #status="{ row }">
        <Button v-if="row.book_house_status == 30" type="success">已预定</Button>
        <Button v-if="row.book_house_status == 20" type="warning">预定中</Button>
        <Button v-if="row.book_house_status == 10" type="error">未预定</Button>
      </template>
    </Table>
    <div style="margin-top: 20px;">
      <Page show-total :total="page.total" show-sizer @on-page-size-change="rowsChange" @on-change="pageChange" />
    </div>
    <!-- 编辑页面 -->
    <Edit v-if="showEdit" :user="editUser" @closeEdit="closeEditModal"></Edit>
    <!-- 添加页面 -->
    <Add v-if="showAdd" @closeAdd="closeAddModal"></Add>
  </div>
</template>
<script>
import { resolveComponent } from "vue";
import { list, del } from "@/api/user";
import config from "@/utils/config";

import Edit from "./Edit";
import Add from "./Add";
export default {
  name: "UserList",
  components: { Edit, Add },
  data() {
    return {
      columns: [
        {
          title: "姓名",
          key: "user_name"
        },
        {
          title: "身份证",
          key: "ID",
        },
        {
          title: "手机号",
          key: "mobile",
        },
        {
          title: "预约状态",
          key: "book_house_status",
          slot: "status"
        },
        {
          title: "操作",
          width: 200,
          render: (h, params) => {
            return h(
              "div",
              {
                class: "option",
              },
              [
                h(
                  resolveComponent("Button"),
                  {
                    type: "primary",
                    style: "margin-right:10px",
                    onClick: () => {
                      this.editUser = params.row;
                      this.editIndex = params.index
                      this.showEdit = true;
                    },
                  },
                  {
                    default() {
                      return "编辑";
                    },
                  }
                ),
                h(
                  resolveComponent("Button"),
                  {
                    type: "error",
                    onClick: () => {
                      this.deleteUser(params.row.user_id, params.index);
                    },
                  },
                  {
                    default() {
                      return "删除";
                    },
                  }
                ),
              ]
            );
          },
        },
      ],
      users: [],
      showModel: false,
      editUser: null,
      showAdd: false,
      showEdit:false,
      editIndex:-1,
      keyword: null,
      page: {},
      rows: 10,
    };
  },
  created() {
    this.getList({})
  },
  methods: {
    getList({ page = 1 }) {
      let keyword = this.keyword;
      let rows = this.rows
      list({ keyword, page, rows }).then((res) => {
        console.log(res.data);
        if (res.code == 0) {
          this.page = {
            total: res.data.total,
            per_page: res.data.per_page,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
          }
          this.users = res.data.data;
        }
      });

    },
    exportModel() {
      window.open(`${config.domain}/uploads/template/user.xlsx`, '_blank');
    },
    deleteUser(_id, index) {
      del(_id).then((res) => {
        if (res.code == 0) {
          this.users.splice(index, 1);
          this.$Notice.success({
            title: "删除成功",
          });
        } else {
          this.$Notice.error({
            title: res.msg,
          });
        }
      });
    },
    showAddModel() {
      this.showAdd = true;
    },
    closeAddModal() {
      this.showAdd = false;
    },
    closeEditModal(u){
      if (this.editIndex!=-1) {
        this.users[this.editIndex] = u
        console.log(u);
      }
      this.showEdit = false;
    },
    pageChange(e) {
      this.getList({ page: e })
    },
    rowsChange(e) {
      this.rows = e
      this.getList({})

    }
  },
};
</script>

<style scoped>
.list {
  width: 100%;
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  margin-top: 50px;
}

.padding-tb {
  padding-bottom: 10px;
}

.option button {
  margin-right: 10px;
}
</style>