/** 管理员API接口文件 */
import request from "./request";
const Req = new request()

export function list({ project_id, building, unit, page, rows }) {
    return Req.get({
        url: '/house/list',
        data: {
            project_id, building, unit, page, rows
        }
    })
}
export function del(house_id) {
    return Req.post({
        url: '/house/delete',
        data: {
            house_id
        }
    })
}
export function add({ project_id, project_name, building, unit, house_no, type, house_type, area, floor }) {
    return Req.post({
        url: "/house/create",
        data: {
            project_id, project_name, building, unit, house_no, type, house_type, area, floor,status:0
        }
    })
}

export function update({ house_id, project_id, project_name, building, unit, house_no, type, house_type, area, floor, status}) {
    return Req.post({
        url: "/house/edit",
        data: {
            house_id, project_id, project_name, building, unit, house_no, type, house_type, area, floor, status
        }
    })
}
