<template>
  <div class="upload">
    <Image :src="u" v-for="u in urls" :key="u" height="200px" fit="fill" />
    <Upload
      :format="format"
      :max-size="2048"
      type="drag"
      multiple
      action="https://upload-z2.qiniup.com"
      :data="uploadParams"
      :on-format-error="addError"
      :on-success="addFileSuccess"
      :before-upload="beforeUpload"
    >
      <div style="padding: 20px 0">
        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p>点击或者拖拽图片到这里上传</p>
      </div>
    </Upload>
  </div>
</template>

<script>
export default {
  name: "QiniuUps",
  data() {
    return {
      format: ["jpg", "jpeg", "png", "webp"],
      uploadParams: {},
      urls: [],
    };
  },
  props: {
    value: {
      default: "",
      type: String,
    },
    url: {
      type: Array,
      default:()=>[]
    },
  },
  created() {
    this.urls = this.url;
  },
  methods: {
    addFileSuccess(request) {
      this.src = request.url;
      this.urls.push(request.url)
      this.$emit("getUrl", this.urls);
    },
    addError() {
      //request, file
      this.$Notice.warning({
        title: "格式错误",
        desc: `请上传${this.format.join("、")}格式文件`,
      });
    },
    async beforeUpload(request) {
      let filename = new Date().getTime() + "_" + request.name;
      let data = await this.$axios.get(
        "http://unicloud.codecore.cn/admin/qiniuToken"
      );
      let token = data.data.token;
      this.uploadParams.token = token;
      this.uploadParams.key = "moxiang/" + filename;
      return this.uploadParams;
    },
  },
};
</script>