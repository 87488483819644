<template>
  <div class="container">
    <div class="left">
      <Carousel v-model="value" autoplay radius-dot arrow="never" loop>
        <CarouselItem class="carouselitem">
          <div class="demo-carousel">
            <img src="../assets/images/banner-1.png" />
            <!-- <h3 class="ivu-typography">完善的权限管理</h3>
            <div class="ivu-typography kk">
              提供菜单按钮权限、数据权限，内置强大的 iCRUD 组件
            </div> -->
          </div>
        </CarouselItem>
        <CarouselItem class="carouselitem">
          <div class="demo-carousel">
            <img src="../assets/images/banner-2.png" />
            <!-- <h3 class="ivu-typography">完善的权限管理</h3>
            <div class="ivu-typography kk">
              提供菜单按钮权限、数据权限，内置强大的 iCRUD 组件
            </div> -->
          </div>
        </CarouselItem>
        <CarouselItem class="carouselitem">
          <div class="demo-carousel">
            <img src="../assets/images/banner-3.png" />
            <!-- <h3 class="ivu-typography">完善的权限管理</h3>
            <div class="ivu-typography kk">
              提供菜单按钮权限、数据权限，内置强大的 iCRUD 组件
            </div> -->
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <div class="right">
      <div class="header">
        <img class="tx" src="../assets/logo_red.png" />
        <h1 class="h1">新华区房源超市管理后台</h1>
        <!-- <div class="ll">
          基于 Admin Plus、iCRUD 和若依的企业级多租户权限管理开发平台
        </div> -->
      </div>
      <!-- <Verify v-if="showVerify" @moveEnd="imgVerify" @close="e => showVerify = e" :style="'right:200px;'"></Verify> -->
      <div class="demo-login">
        <Login @on-submit="handleSubmit" class="login">
          <UserName name="username" :value="userInfo.username" enter-to-submit />
          <Password name="password" :value="userInfo.password" />
          <div class="demo-auto-login">
            <!-- <Checkbox v-model="autoLogin" size="large">自动登录</Checkbox>
            <a>忘记密码</a> -->
          </div>
          <Submit />
        </Login>

      </div>
      <!-- <div class="middle">
        <span>登录其它账号：</span>
        <button class="ivu-btn ivu-btn-small" type="button">管理员</button>
        <button class="ivu-btn ivu-btn-small" type="button">人事</button>
        <button class="ivu-btn ivu-btn-small" type="button">经理</button>
        <button class="ivu-btn ivu-btn-small" type="button">老板</button>
        <button class="ivu-btn ivu-btn-small" type="button">普通用户</button>
      </div> -->
      <div class="footer">
        <!-- <a class="a" href="https://www.iviewui.com" target="_blank" title="官网">官网</a>
        <a class="a" href="https://devpress.cn/viewdesign" target="_blank" title="社区">社区</a>
        <a class="a" href="https://www.iviewui.com/admin-cloud" target="_blank" title="Admin Cloud">Admin Cloud</a> -->
        <div class="footers" style="margin-top: 10px;">
          Copyright © 2023 新华区房源超市
        </div>
        <div class="footers" style="margin-top: 10px;">
          版权所有 豫ICP备05082262号-1 服务电话：0375-1586599 / 25645868
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/admin";
import Verify from "@/components/common/Verify";
export default {
  data() {
    return {
      value: 0,
      autoLogin: true,
      userInfo: {
        username: "",
        password: "",
      },
      veriftResult: false,
      showVerify: false,
      verifyFlag: false
    };
  },
  components: {
    Verify
  },
  created() {
    if (localStorage.userInfo) {
      let userInfo = JSON.parse(localStorage.userInfo);
      if (userInfo.username) {
        // this.userInfo = userInfo;
        // this.handleSubmit(true, userInfo)
      }
    }
  },
  // mounted() {
  //   window.addEventListener('keydown', this.keyDown)
  // },
  // beforeUnmount() {
  //   window.removeEventListener('keydown', this.keyDown)
  // },
  methods: {
    keyDown(e) {
      if (e.key === "Enter") {
        console.log(this.userInfo);

        // this.handleSubmit(true, this.userInfo)
      }
    },
    imgVerify(e) {
      this.veriftResult = e.result;
      if (e.result) {
        this.$Message.success({
          content: "验证成功,请点击登录按钮!",
        });
        this.showVerify = false;
        this.verifyFlag = true;
        this.veriftResult = true;

      } else {
        this.$Modal.error({
          content: "验证失败,请点击登录按钮重新验证",
        })
        this.showVerify = false;
      }
    },
    handleSubmit(valid, { username, password }) {
      // if (!this.verifyFlag) {
      //   this.showVerify = true;
      //   return;
      // }

      // if (valid) {
      const msg = this.$Message.loading({
        content: '正在登录...',
        duration: 0
      });
      login({
        username,
        password,
      }).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          localStorage.token = res.data.token;
          localStorage.token_outtime = (new Date).getTime() + 3600 * 2 * 1000;
          // 缓存 无法直接存放对象和数组数据 需要用JSON转化成字符串才能存储
          // localStorage.userInfo = JSON.stringify({ username, password });
          this.$store.commit("setAdminInfo", {
            username: res.username
          });
          const msg = this.$Message.loading({
            content: '正在登录...',
            duration: 0
          });
          setTimeout(() => {
            msg()
            this.$router.push("/admin-index")
          }, 1500);
          // this.$router.push("/admin-index")
          // ;
        } else {
          this.$Modal.info({
            title: "消息提醒：",
            content: "账号密码错误",
          });
        }
      }).finally(() => {
        msg();
      });
      // } else {
      //   const msg = this.$Message.loading({
      //     content: '正在登录...',
      //     duration: 0
      //   });
      //   setTimeout(() => {
      //     msg()
      //     this.$router.push("/admin-index")
      //   }, 1500);
      // }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
}

.demo-carousel {
  text-align: center;
}

.ivu-carousel {
  height: 100vh;
  position: relative;
}

.ivu-carousel-item {
  margin-top: 130px;
}

.left {
  width: 50%;
  height: 100%;
  background-color: #1850c0;
}

img {
  width: 700px;
  height: 100%;
}

h3.ivu-typography {
  color: white;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}

div.kk {
  color: white;
  font-size: 14px;
}

.right {
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;
}

.demo-login {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}

.login {
  width: 424px;
}

.tx {
  width: 100px;
  height: 100px;
  margin-top: 50px;
  border-radius: 50%;
  background: #f2f2f3;
}

.h1 {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
}

.ll {
  font-size: 14px;
  color: #515a6e;
  margin-top: 5px;
}

.demo-auto-login {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.middle {
  margin-top: 28px;
  margin-left: 5.5px;
}

.ivu-btn {
  margin: 0 7.5px;
  color: #515a6e;
  font-size: 14px;
}
</style>