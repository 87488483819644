import { createApp } from 'vue'
/** 使用viewUI */
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'

/** 页面自带 */
import App from './App.vue'
import store from './store'
import router from './router'

const app = createApp(App)
/** 挂载使用axios */
import _axios from './plugins/axios'
app.config.globalProperties.$axios = _axios

// 自定义全局组件
import QiniuUp from "./components/utils/QiniuUp";
import QiniuUps from "./components/utils/QiniuUps";
import UploadServer from "./components/utils/UploadServer";

import TEditor from '@/components/TEditor.vue'
app.component('TEditor', TEditor)

app.use(router).use(store).use(ViewUIPlus).component("QiniuUp", QiniuUp).component("QiniuUps", QiniuUps).component("UploadServer", UploadServer).mount('#app')
