<template>
  <div class="list">
    <PageHeader title="楼栋管理" hidden-breadcrumb>
      <template #action>
        <Row>
          <Input v-model="building" placeholder="输入楼栋号..." clearable style="width: 100px;margin-right: 10px;" />
          <Input v-model="unit" placeholder="输入单元..." clearable style="width: 100px;;margin-right: 10px;" />
          <Button type="primary" @click="getList">
            <Icon type="md-search" size="24" />
          </Button>
          <Button type="primary" @click="cellAdd">添加房源</Button>
        </Row>
      </template>
    </PageHeader>
    <Table border :columns="columns" :data="cells">
      <template #type="{ row }">
        <span v-if="row.type == 0">商品房</span>
        <span v-if="row.type == 1">公寓 </span>
        <span v-if="row.type == 2">商铺</span>
      </template>
      <template #status="{ row }">
        <Button v-if="row.status == 2">无</Button>
        <Button v-if="row.status == 10" type="warning">预定成功</Button>
        <Button v-if="row.status == 20" type="success">确认成功</Button>
        <Button v-if="row.status == 30" type="error">预定失败</Button>
      </template>

      <template #options="{ row, index }">
        <div class="options">
          <Button type="primary" style="margin-right: 10px;" @click="addCell(row)">编辑</Button>
          <Button type="error" @click="deleteProject(row.house_id, index)">删除</Button>
        </div>
      </template>
    </Table>
    <div style="margin-top: 20px;">
      <Page show-total :total="page.total" show-sizer @on-page-size-change="rowsChange" @on-change="pageChange" />
    </div>

    <Modal v-model="addCellModal" :title="data.house_id ? '编辑房源' : '添加房源'" @on-ok="sureData">
      <div style="text-align: center">
        <Form :model="data" :label-width="80">
          <FormItem label="楼号:">
            <!-- <Input v-model="data.building" placeholder="请输入楼号"></Input> -->
            <Select v-model="data.building">
              <Option :value="b.building" v-for="(b, bi) in buildSelect" :key="`b-${b.building}`"
                @click="selectBuild(bi)">
                {{ b.building }}</Option>
            </Select>
          </FormItem>
          <FormItem label="单元号:">
            <!-- <Input v-model="data.unit" placeholder="请输入单元号"></Input> -->
            <!-- @click="selectUnit(ui)" -->
            <Select v-model="data.unit">
              <Option :value="u" v-for="(u) in unitSelect" :key="`u-${u}`">{{ u }}</Option>
            </Select>
          </FormItem>
          <FormItem label="房间号:">
            <Input v-model="data.house_no" placeholder="请输入房间号"></Input>
          </FormItem>
          <FormItem label="类型:" style="text-align: left;">
            <RadioGroup v-model="data.type">
              <Radio :label="0">商品房</Radio>
              <Radio :label="1">公寓</Radio>
              <Radio :label="2">商铺</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="户型:">
            <Input v-model="data.house_type" placeholder="请输入户型"></Input>
          </FormItem>
          <FormItem label="楼层:">
            <Input v-model="data.floor" type="number" placeholder="请输入户型楼层"></Input>
          </FormItem>
          <FormItem label="面积:">
            <Input v-model="data.area" type="number" placeholder="请输入户型面积"></Input>
          </FormItem>
          <!-- <FormItem label="交房说明:">
            <Input v-model="data.delivery_time"  placeholder="请输入交房说明"></Input>
          </FormItem> -->
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import { list, del, add, update } from "@/api/cell";

import { getone } from "@/api/house";
export default {
  name: "CellList",
  data() {
    return {
      id: 0,
      columns: [
        {
          title: "编号",
          key: "house_id",
        },
        {
          title: "楼栋",
          key: "building"
        },
        {
          title: "单元",
          key: "unit",
        },
        {
          title: "房号",
          key: "house_no"
        },
        {
          title: "楼层",
          key: "floor",
        },
        {
          title: "面积",
          key: "area"
        },
        {
          title: "说明",
          key: "house_type",
        },

        {
          title: "房屋类型",
          key: "type",
          slot: "type"
        },
        {
          title: "房屋状态",
          key: "status",
          slot: "status"
        },
        // {
        //   title: "交房说明",
        //   key: "delivery_time"
        // },
        {
          title: "项目名",
          key: "project_name"
        },
        {
          title: "操作",
          width: 200,
          slot: "options"
        }
      ],
      data: {},
      cells: [],
      showModel: false,
      editUser: null,
      addCellModal: false,
      building: "",
      unit: "",
      page: {},
      rows: 10,
      bsIndex: 0,
      usIndex: 0,
      buildSelect: [],
      unitSelect: [],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    this.getList({})
    this.getDetail()
  },
  methods: {
    getDetail() {
      getone(this.id).then(res => {
        if (res.code == 0) {
          this.buildSelect = res.data.building_list
          this.unitSelect = res.data.building_list[0].unit
        }
      })
    },
    getList({ page = 1 }) {
      let rows = this.rows
      let project_id = this.id;
      let building = this.building;
      let unit = this.unit
      list({ project_id, building, unit, page, rows }).then((res) => {
        if (res.code == 0) {
          this.page = {
            total: res.data.total,
            per_page: res.data.per_page,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
          }
          this.cells = res.data.data;
        }
      });

    },
    selectBuild(idx) {
      this.unitSelect = this.buildSelect[idx].unit
    },
    deleteProject(_id, index) {
      this.$Modal.confirm({
        title: "确定要删除该数据吗?",
        onOk: () => {
          console.log(123456);
          del(_id).then((res) => {
            if (res.code == 0) {
              this.cells.splice(index, 1);
              this.$Notice.success({
                title: "删除成功",
              });
            } else {
              this.$Notice.error({
                title: res.msg,
              });
            }
          });
        }
      })

    },
    addCell(item) {
      this.buildSelect.map((v, k) => {
        if (v.building == item.building) {
          this.unitSelect = this.buildSelect[k].unit
        }
      })
      if (this.id == 0) {
        this.data.project_name = this.$route.query.name
      } else {
        this.data = item;
      }
      this.addCellModal = true;
    },
    cellAdd() {
      this.data = {},
        this.addCellModal = true;
    },
    sureData() {
      if (!this.data.house_id) {
        this.data.project_id = this.id
        add(this.data).then(res => {
          if (res.code == 0) {
            this.$Notice.success({
              title: res.msg
            })
          } else {
            this.$Notice.error({
              title: res.msg
            })
          }
          this.getList({})
        })
      } else {
        update(this.data).then(res => {
          if (res.code == 0) {
            this.$Notice.success({
              title: "房源信息已更新"
            })
          } else {
            this.$Notice.error({
              title: res.msg
            })
          }
          this.getList({})
        })
      }
    },
    pageChange(e) {
      this.getList({ page: e })
    },
    rowsChange(e) {
      this.rows = e
      this.getList({})

    }
  },
};
</script>

<style scoped>
.list {
  width: 100%;
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  margin-top: 50px;
}

.padding-tb {
  padding-bottom: 10px;
}

.option button {
  margin-right: 10px;
}
</style>