import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import ErrorPage from "../views/ErrorPage.vue";

import HouseList from "../views/house/List.vue"
import HouseAdd from "../views/house/Add.vue"
import HouseEdit from "../views/house/Edit.vue"
import Cell from "../views/house/Cell.vue";
import UserList from "../views/user/List.vue"
import UpPwd from "../views/admin/UpPwd.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
    meta: {
      title: '管理台 - 登陆',
      keepAlive: false
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: '管理台 - 登陆',
      keepAlive: false
    }
  }, {
    path: '/admin-index',
    name: 'admin-index',
    component: () => import("../views/MainView.vue"),
    children: [{
      path: '',
      name: 'Main',
      component: () => import("../views/IndexView.vue")
    }, {
      path: "adminlist",
      component: () => import("../views/admin/List.vue"),
    }, {
      path: "update-pwd",
      component: UpPwd
    },
    // 房源管理
    {
      path: "houselist",
      component: HouseList,
    },
    {
      path: "house-add",
      component: HouseAdd,
    },
    {
      path: "house-edit",
      component: HouseEdit,
    },
    {
      path: "cell",
      component: Cell,
    },
    {
      path: "gcate",
      name: "GoodsCate",
      component: () => import("../views/goods/cate/List.vue"),
    }, {
      path: "users",
      name: "User",
      component: UserList
    }, {
      path: "fundlist",
      name: "PayOrder",
      component: () => import("../views/fund/PayOrderList.vue"),
    }, {
      path: "noticlist",
      name: "NoticList",
      component: () => import("../views/media/notice/List.vue"),
    }, {
      path: "bannerlist",
      name: "BannerList",
      component: () => import("../views/media/banner/List.vue"),
    }, {
      path: "orderlist",
      name: "OrderList",
      component: () => import("../views/order/List.vue"),
    }, {
      path: 'goods',
      name: 'GoodsList',
      component: () => import("@/views/goods/goods/List")
    }, {
      path: 'goodsadd',
      name: 'GoodsAdd',
      component: () => import("@/views/goods/goods/GoodsAdd")
    }
    ]
  },
  {
    path: '/404',
    name: 'NotFound',
    component: ErrorPage,
    hidden: true
  },
  {
    path: "/:pathMatch(.*)",
    name: "noMatch",
    redirect: "/404",
    hidden: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  document.title = "新华区房源超市管理后台"
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  if (to.path == "/" || to.path == "/login") {
    // console.log("正常访问 没啥可干的");
    return true
  } else {
    let time = (new Date).getTime();
    // 如果当前时间戳比缓存超期时间大 就强制跳转到登录页面
    // 或者 token没有内容也跳转到登录页面
    if (!localStorage.token) {
      router.push("/login")
    }
    if (time + 5000 > localStorage.token_outtime - 0) {
      router.push("/login")
    } else {
      // 验证token是否合法
      return true
    }


  }
})
export default router
