<template>
  <div class="list">
    <PageHeader class="padding-tb" title="楼盘列表" hidden-breadcrumb>
      <template #action>
        <Row>
          <Input v-model="keyword" placeholder="输入要查询的楼盘名..." clearable style="width: 300px;" />
          <Button type="primary" @click="getList">
            <Icon type="md-search" size="24" />
          </Button>

          <Button type="primary" @click="addProject(0)">添加</Button>
        </Row>
      </template>
    </PageHeader>
    <Table border ref="selection" :columns="columns" :data="houses">
      <template #login-time="{ row }">
        {{ row.last_start }}
      </template>
      <template #square_price="{ row }">
        <div style="">
          <span style="color:gray">均价</span>
          {{ row.square_price }}
        </div>

      </template>
      <template #options="{ row, index }">
        <div class="options">
          <Button size="small" type="success" style="margin-right: 10px;"
            @click="goHousePage(row.project_id, row.name)">房源管理</Button>
          <Button size="small"  type="info" style="margin-right: 10px;" @click="editCell(row.project_id)">楼栋管理</Button>
          <Button size="small"  type="primary" style="margin-right: 10px;" @click="editProject(row.project_id)">编辑</Button>
          <Button size="small"  type="error" @click="deleteProject(row.project_id, index)">删除</Button>
        </div>
      </template>

    </Table>
    <div style="margin-top: 20px;">
      <Page show-total :total="page.total" @on-change="pageChange" />
    </div>
  </div>
</template>
<script>
import { resolveComponent } from "vue";
import { list, remove as del } from "@/api/house";
import moment from "moment";

export default {
  name: "ProjectList",
  data() {
    return {
      keyword: "",
      page: {},
      columns: [
        {
          title: "房源",
          key: "name",
        },
        {
          title: "房源缩略图",
          key: "avatarUrl",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h(resolveComponent("Image"), {
                src: params.row.image,
                fit: "fit",
                width: "120px",
                height: "120px",
                preview: true,
                "preview-list": [params.row.image],
              }),
            ]);
          },
        },
        {
          title: "楼盘地址",
          key: "address",
        },
        {
          title: "每平米单价",
          key: "square_price", slot: "square_price"
        },
        {
          title: "开盘时间",
          key: "last_start",
          slot: "login-time",
        },
        {
          title: "交房说明",
          key: "delivery_time"
        },
        {
          title: "操作",
          width: 350,
          slot: "options"
        },
      ],
      houses: [],
      showDelectMsg: false,
      editUser: null,
      showAdd: false,
    };
  },
  computed: {
    formartTime() {
      return function (val) {
        let date = new Date(val);
        return moment(date).format("YYYY-MM-DD H:mm:s");
      };
    },
  },
  created() {
    this.getList({})
  },
  methods: {
    getList({ page = 1, rows = 10 }) {
      let name = this.keyword;
      list({ name, page, rows }).then((res) => {
        if (res.code == 0) {
          this.page = {
            total: res.data.total,
            per_page: res.data.per_page,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
          }
          console.log(res.data);
          this.houses = res.data.data;
        }
      });
    },
    addProject(id) {
      this.$router.push(`/admin-index/house-add?id=${id}`);
    },
    editProject(id) {
      this.$router.push(`/admin-index/house-add?id=${id}`);
    },
    editCell(id){
      let t = 'cell';
      this.$router.push(`/admin-index/house-add?id=${id}&type=${t}`);
    },
    goHousePage(id, name) {
      this.$router.push(`/admin-index/cell?id=${id}&name=${name}`);
    },
    deleteProject(_id, index) {
      this.$Modal.confirm({
        title: "确定要删除该数据吗?",
        onOk: () => {
          del(_id).then((res) => {
            if (res.code == 0) {
              this.houses.splice(index, 1);
              this.$Notice.success({
                title: "删除成功",
              });
            } else {
              this.$Notice.error({
                title: res.msg,
              });
            }
          });
        }
      })


    },
    pageChange(e) {
      this.getList({ page: e })
    }
  },
};
</script>

<style scoped>
.list {
  width: 100%;
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  margin-top: 50px;
}

.padding-tb {
  padding-bottom: 10px;
}

.option button {
  margin-right: 10px;
}
</style>