<template>
    <div class="list">
        <PageHeader>
            <template #title>
                <span>修改管理员密码</span>
            </template>
        </PageHeader>

        <Form :model="editUser" :label-width="80">
            <FormItem label="新密码:">
                <Input v-model="editUser.new_password" placeholder="请输入新密码"></Input>
            </FormItem>
            <FormItem label="当前密码:">
                <Input v-model="editUser.current_password" placeholder="请输入当前密码"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="submitForm">确定修改</Button>
            </FormItem>
        </Form>

    </div>
</template>
  
<script>
import { uppwd } from "@/api/admin";
export default {
    name: "AdminPassword",
    data() {
        return {
            model: true,
            data: [],
            editUser: {
                new_password: "",
                current_password: ""
            }
        };
    },
    methods: {
        submitForm() {
            uppwd(this.editUser).then(res => {
                if (res.code == 0) {
                    this.$Notice.success({
                        title: res.msg
                    })
                } else {
                    this.$Notice.error({
                        title: res.msg
                    })
                }
            })
        }
    },
};
</script>
  
<style>
.list {
    width: 100%;
    margin: 0 10px;
    padding: 10px;
    background: #fff;
    margin-top: 50px;
}
</style>