<template>
  <Modal v-model="model" width="500" @on-ok="submitForm" @on-cancel="onCancle">
    <template #header>
      <p style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>用户信息添加</span>
      </p>
    </template>
    <div style="text-align: center">
      <Form :model="editUser" :label-width="80">
        <FormItem label="姓名:">
          <Input v-model="editUser.user_name" placeholder="请输入用户姓名"></Input>
        </FormItem>

        <FormItem label="身份证:">
          <Input v-model="editUser.ID" placeholder="请输入用户身份证"></Input>
        </FormItem>

        <FormItem label="手机号:">
          <Input v-model="editUser.mobile" placeholder="请输入用户手机号"></Input>
        </FormItem>
      </Form>
    </div>
  </Modal>
</template>

<script>
import { add } from "@/api/user";
export default {
  name: "UserAdd",
  data() {
    return {
      model: true,
      editUser: {
        ID: "",
        mobile: "", //管理员名称
        user_name: ""
      }
    };
  },
  created() {
  },
  methods: {
    submitForm() {
      add(this.editUser).then(res => {
        if (res.code == 0) {
          this.$Notice.success({
            title: "添加成功"
          })
          this.model = false;
          this.$emit("closeAdd")
        } else {
          this.$Notice.error({
            title: res.msg
          })
        }
      }).then(()=>{
        this.model = false;
      })
    },
    onCancle() {
      this.model = false;
      this.$emit("closeAdd")
    }
  },
};
</script>

<style>
.tag-self {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #f2f2f2;
  padding: 0 4px;
  margin: 4px;
}

.active {
  color: #2d8cf0;
  border-color: #2d8cf0;
}
</style>