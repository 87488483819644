<template>
  <Modal v-model="model" width="500" @on-ok="submitForm" @on-cancel="onCancle">
    <template #header>
      <p style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>管理员信息编辑</span>
      </p>
    </template>
    <div style="text-align: center">
      <Form :model="editUser" :label-width="80">
        <FormItem label="账户:">
          <Input
            v-model="editUser.username"
            placeholder="请输入用户账号"
          ></Input>
        </FormItem>
        <!-- 头像上传 -->
        <FormItem label="头像:">
          <Avatar size="large" :src="editUser.avatarUrl"></Avatar>
          <Upload
            :format="['jpg', 'jpeg', 'png']"
            :max-size="2048"
            type="drag"
            action="https://upload-z2.qiniup.com"
            :data="uploadParams"
            :on-format-error="addError"
            :on-success="addFileSuccess"
            :before-upload="beforeUpload"
          >
            <div style="padding: 20px 0">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>点击或者拖拽图片到这里上传</p>
            </div>
          </Upload>
        </FormItem>

        <FormItem label="电话:">
          <Input v-model="editUser.tel" placeholder="请输入电话号"></Input>
        </FormItem>
        <FormItem label="角色">
          <Select v-model="editUser.roles">
            <Option value="superadmin">Superadmin</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </FormItem>

        <FormItem label="密码:">
          <Input v-model="editUser.password" placeholder="请输入密码"></Input>
        </FormItem>
      </Form>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "AdminEidt",
  data() {
    return {
      model: true,
      editUser: {
        _id:"",
        username: "",
        password: "",
        tel: "",
        avatarUrl: "",
        roles: "",
      },
      uploadParams: {},
    };
  },
  props: {
    user: null,
  },
  created() {
    this.editUser = this.user;
  },
  methods: {
    submitForm(){
      this.$axios.post("http://unicloud.codecore.cn/admin/adminupdate",this.editUser).then(res=>{
        if(res.code == 0){
          console.log(res);
        }
        this.$emit("closeEdit")
      })
    },
    onCancle(){
      this.$emit("closeEdit")
    },
    addFileSuccess(request, file, list) {
      console.log(file, list, request.key, "上传成功");
      this.editUser.avatarUrl = "https://media.codecore.cn/"+request.key
    },
    addError(request, file) {
      console.log(request, file);
      this.$Notice.warning({
        title: "格式错误",
        desc: "请上传png、jpg、jpeg格式文件",
      });
    },
    async beforeUpload(request) {
      let filename = new Date().getTime() + "_" + request.name;
      let data = await this.$axios.get(
        "http://unicloud.codecore.cn/admin/qiniutoken"
      );
      let token = data.data.token;
      this.uploadParams.token = token;
      // this.imgQiniuToken = token;
      // this.imgQiniuUrlPre = "moxiang";
      this.uploadParams.key = "moxiang/"+filename;
      return this.uploadParams
    },
  },
};
</script>