/** 管理员API接口文件 */
import request from "./request";
const Req = new request()
export function login({ username, password }) {
    return Req.post({
        url: "/passport/login",
        data: { account: username, password }
    })
}

export function loginout() {
    return Req.post({
        url: "/loginout",
        data: {}
    })
}

export function list({ keyword, page }) {
    return Req.get({
        url: '/manager/list',
        data: {
            keyword, page
        }
    })
}
export function getone(id) {
    return Req.get({
        url: `/manager/detail?manager_id=${id}`,
        data: {
        }
    })
}
export function del(manager_id) {
    return Req.post({
        url: '/manager/delete',
        data: {
            manager_id
        }
    })
}
export function add({ account, password, location_list, manager_name }) {
    return Req.post({
        url: "/manager/create",
        data: {
            account, password, location_list, manager_name
        }
    })
}

export function update({ manager_id, location_list, account, manager_name }) {
    return Req.post({
        url: "/manager/edit",
        data: { manager_id, location_list, account, manager_name }
    })
}

export function updatePwd(manager_id, password) {
    return Req.post({
        url: "/manager/password",
        data: { manager_id, password }
    })
}

export function uppwd({
    new_password,
    current_password
}) {
    return Req.post({
        url: "/passport/password",
        data: {
            new_password,
            current_password
        }
    })
}
